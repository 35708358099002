.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 32px;
  width: 32px;
  font-size: 21px;
  line-height: 32px;
}

.avatar.img-thumbnail {
  padding: 4px;
  max-width: none;
}

.avatar > * {
  height: 100%;
  width: 100%;
}

.avatar-16 {
  height: 16px;
  width: 16px;
  font-size: 11px;
  line-height: 16px;
}

.avatar-24 {
  height: 24px;
  width: 24px;
  font-size: 16px;
  line-height: 24px;
}

.avatar-32 {
  height: 32px;
  width: 32px;
  font-size: 21px;
  line-height: 32px;
}

.avatar-48 {
  height: 48px;
  width: 48px;
  font-size: 32px;
  line-height: 48px;
}

.avatar-64 {
  height: 64px;
  width: 64px;
  font-size: 43px;
  line-height: 64px;
}

.avatar-96 {
  height: 96px;
  width: 96px;
  font-size: 64px;
  line-height: 96px;
}

.avatar-128 {
  height: 128px;
  width: 128px;
  font-size: 85px;
  line-height: 128px;
}

@media (min-width: 576px) {
  .avatar-sm-16 {
    height: 16px;
    width: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  .avatar-sm-24 {
    height: 24px;
    width: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .avatar-sm-32 {
    height: 32px;
    width: 32px;
    font-size: 21px;
    line-height: 32px;
  }
  .avatar-sm-48 {
    height: 48px;
    width: 48px;
    font-size: 32px;
    line-height: 48px;
  }
  .avatar-sm-64 {
    height: 64px;
    width: 64px;
    font-size: 43px;
    line-height: 64px;
  }
  .avatar-sm-96 {
    height: 96px;
    width: 96px;
    font-size: 64px;
    line-height: 96px;
  }
  .avatar-sm-128 {
    height: 128px;
    width: 128px;
    font-size: 85px;
    line-height: 128px;
  }
}

@media (min-width: 768px) {
  .avatar-md-16 {
    height: 16px;
    width: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  .avatar-md-24 {
    height: 24px;
    width: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .avatar-md-32 {
    height: 32px;
    width: 32px;
    font-size: 21px;
    line-height: 32px;
  }
  .avatar-md-48 {
    height: 48px;
    width: 48px;
    font-size: 32px;
    line-height: 48px;
  }
  .avatar-md-64 {
    height: 64px;
    width: 64px;
    font-size: 43px;
    line-height: 64px;
  }
  .avatar-md-96 {
    height: 96px;
    width: 96px;
    font-size: 64px;
    line-height: 96px;
  }
  .avatar-md-128 {
    height: 128px;
    width: 128px;
    font-size: 85px;
    line-height: 128px;
  }
}

@media (min-width: 992px) {
  .avatar-lg-16 {
    height: 16px;
    width: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  .avatar-lg-24 {
    height: 24px;
    width: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .avatar-lg-32 {
    height: 32px;
    width: 32px;
    font-size: 21px;
    line-height: 32px;
  }
  .avatar-lg-48 {
    height: 48px;
    width: 48px;
    font-size: 32px;
    line-height: 48px;
  }
  .avatar-lg-64 {
    height: 64px;
    width: 64px;
    font-size: 43px;
    line-height: 64px;
  }
  .avatar-lg-96 {
    height: 96px;
    width: 96px;
    font-size: 64px;
    line-height: 96px;
  }
  .avatar-lg-128 {
    height: 128px;
    width: 128px;
    font-size: 85px;
    line-height: 128px;
  }
}

@media (min-width: 1200px) {
  .avatar-xl-16 {
    height: 16px;
    width: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  .avatar-xl-24 {
    height: 24px;
    width: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .avatar-xl-32 {
    height: 32px;
    width: 32px;
    font-size: 21px;
    line-height: 32px;
  }
  .avatar-xl-48 {
    height: 48px;
    width: 48px;
    font-size: 32px;
    line-height: 48px;
  }
  .avatar-xl-64 {
    height: 64px;
    width: 64px;
    font-size: 43px;
    line-height: 64px;
  }
  .avatar-xl-96 {
    height: 96px;
    width: 96px;
    font-size: 64px;
    line-height: 96px;
  }
  .avatar-xl-128 {
    height: 128px;
    width: 128px;
    font-size: 85px;
    line-height: 128px;
  }
}

@media (min-width: 1400px) {
  .avatar-xxl-16 {
    height: 16px;
    width: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  .avatar-xxl-24 {
    height: 24px;
    width: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .avatar-xxl-32 {
    height: 32px;
    width: 32px;
    font-size: 21px;
    line-height: 32px;
  }
  .avatar-xxl-48 {
    height: 48px;
    width: 48px;
    font-size: 32px;
    line-height: 48px;
  }
  .avatar-xxl-64 {
    height: 64px;
    width: 64px;
    font-size: 43px;
    line-height: 64px;
  }
  .avatar-xxl-96 {
    height: 96px;
    width: 96px;
    font-size: 64px;
    line-height: 96px;
  }
  .avatar-xxl-128 {
    height: 128px;
    width: 128px;
    font-size: 85px;
    line-height: 128px;
  }
}

.kg-width-wide {
  grid-column: wide-start / wide-end;
}

.kg-width-wide img {
  height: auto;
  max-width: 100%;
}

.kg-width-wide.kg-card-hascaption img {
  grid-column: wide-start / wide-end;
}

.kg-width-full {
  grid-column: full-start / full-end;
}

.kg-width-full img {
  height: auto;
  width: 100%;
}

.kg-width-full.kg-card-hascaption {
  display: grid;
  grid-template-columns: inherit;
}

.kg-width-full.kg-card-hascaption img {
  grid-column: 1 / -1;
}

.kg-width-full.kg-card-hascaption, .kg-width-full.kg-card-hascaption figcaption {
  grid-column: main-start / main-end;
}

.kg-bookmark-author,
.kg-bookmark-publisher {
  display: inline;
}

.kg-image {
  margin: 0 auto;
  max-width: 100%;
}

.kg-image-card img {
  height: auto;
  margin: auto;
}

.kg-gallery-container {
  display: flex;
  flex-direction: column;
}

.kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.kg-gallery-row:not(:first-of-type) {
  margin: .75em 0 0;
}

.kg-gallery-image img {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
}

.kg-gallery-image:not(:first-of-type) {
  margin: 0 0 0 .75em;
}

.kg-bookmark-card {
  width: 100%;
}

.kg-bookmark-card .kg-bookmark-container {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: flex;
  min-height: 148px;
  border-radius: 3px;
  color: #212529;
}

@media (max-width: 575.98px) {
  .kg-bookmark-card .kg-bookmark-container {
    flex-direction: column;
  }
}

.kg-bookmark-title {
  color: #212529;
  font-weight: 600;
}

.kg-bookmark-description {
  display: -webkit-box;
  overflow-y: hidden;
  margin-top: 12px;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.kg-bookmark-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

@media (max-width: 575.98px) {
  .kg-bookmark-content {
    order: 2;
  }
}

.kg-bookmark-thumbnail {
  position: relative;
  min-width: 33%;
  max-height: 100%;
}

.kg-bookmark-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 3px 3px 0;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 575.98px) {
  .kg-bookmark-thumbnail {
    order: 1;
    min-height: 160px;
    width: 100%;
  }
}

.kg-bookmark-metadata {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 14px;
  font-weight: 400;
}

.kg-bookmark-metadata .kg-bookmark-icon {
  margin-right: 8px;
  width: 22px;
  height: 22px;
}

.kg-bookmark-publisher {
  overflow: hidden;
  max-width: 240px;
  line-height: 1.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overlay, .subscribe-overlay {
  z-index: 2000;
  opacity: 0;
  pointer-events: none;
}

.overlay:target, .subscribe-overlay:target {
  z-index: 2001;
  opacity: 1;
  transition: opacity 0.2s ease-in;
  pointer-events: auto;
}

/**
 * Full page overlay when user clicks 'Subscribe' CTA
 */
/**
 * portal.min.js uses three classes on data-members-form to indicate state:
 *   success, error and loading.
 */
.subscribe-form.invalid .message-error, .subscribe-form.error .message-error,
form[data-members-form].invalid .message-error,
form[data-members-form].error .message-error {
  display: block;
}

.subscribe-form.success .message-success,
form[data-members-form].success .message-success {
  display: block;
}

.subscribe-form.loading .button-content,
form[data-members-form].loading .button-content {
  display: none;
}

.subscribe-form.loading .loader, .subscribe-form.loading .button-loader,
form[data-members-form].loading .loader,
form[data-members-form].loading .button-loader {
  display: block;
}

.subscribe-form .loader, .subscribe-form .button-loader,
form[data-members-form] .loader,
form[data-members-form] .button-loader {
  display: none;
}

.subscribe-form .message-error,
.subscribe-form .message-success,
form[data-members-form] .message-error,
form[data-members-form] .message-success {
  display: none;
}

/**
 * Email confirmation/failure messages
 * These messages are shown by JS when the url contains success=false
 */
.subscribe-notification {
  z-index: 3000;
  visibility: hidden;
}

.subscribe-notification.close {
  visibility: hidden !important;
  transition: none;
}

.subscribe-success .subscribe-success-message,
.subscribe-failure .subscribe-failure-message {
  visibility: visible;
}

